* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30rem;
  margin: auto;
  gap: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 90vh;
}

.main-heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sub-heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* OAuthScreen */

.permission-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 2rem;
}

/* Selector */

.label {
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.25rem;
}

.selector {
  width: 15rem;
}

/* CustomDescriptionBuilder */

.description-box {
  width: 100%;
  min-height: 6rem;
  resize: vertical;
  padding: 0.5rem;
  font: inherit;
  font-size: 0.875rem;
}

.variable-container {
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 10px;
}

.variable {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  background: white;
  cursor: pointer;
  color: #323338;
}

.variable-selected {
  background: #28c882;
  color: white;
  border-color: transparent;
}

.variable-disabled {
  pointer-events: none;
  opacity: 0.8;
}

.variable:hover {
  background: #ddd;
  color: #323338;
  border-color: gray;
}

/* ButtonGroup */

.button-group {
  margin-top: 1rem;
}

.button {
  width: 200px;
}

.button-2 {
  width: 150px;
}

/* AddColumnModal */

.modal {
  margin-top: 1rem;
  min-height: 18vh;
}

.modal-flex {
  width: 100%;
}

.column-type-selector,
.column-name-field {
  width: 12rem;
}

/* MissingFieldsWarning */

.missing-fields-warning {
  background: var(--negative-color-selected);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.missing-fields-warning ul {
  margin: 0.5rem;
  padding: 0 0.5rem;
}

/* ValidationError */

.validation-error {
  width: 100%;
}

/* SubscribeNudge */

.subscribe-nudge {
  background: var(--warning-color-selected);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

/* Recipe */

.recipe {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #000;
  cursor: pointer;
  user-select: none;
  min-width: 480px;
}

.recipe-selector {
  width: 10rem;
}

.recipe-enabled {
  border-color: #d5d8df;
}

.recipe-disabled {
  border-color: #777;
}

.recipe-enabled .recipe-text {
  color: #d5d8df;
}

.recipe-disabled .recipe-text {
  color: #777;
}

.light-app-theme .recipe-enabled {
  border-color: #323338;
}

.light-app-theme .recipe-disabled {
  border-color: #999;
}

.light-app-theme .recipe-enabled .recipe-text {
  color: #323338;
}

.light-app-theme .recipe-disabled .recipe-text {
  color: #999;
}
